// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';

// Styles
import { layoutContainer } from './styles.module.css';

export default (data) => {
  const {
    titleHeading,
    titleSubheading,
    pageLogo,
    pageHeading,
    pageBannerText,
    pageBackgroundImage,
    ticketInformation,
    winnersLink,
  } = data.pageContext.data;

  return (
    <LayoutContainer
      includeOrderTicketsCta
      includeWinnersLink={winnersLink}
    >
      <div className="theme-background-primary">
        <div className={layoutContainer}>
          <TitleBlock
            mainHeading={titleHeading}
            subHeading={titleSubheading}
            onThemeBackground
          />
          <div
            className="u-full-width l-margin-bottom l-margin-top--40 u-center-canvas addon-page"
            style={pageBackgroundImage && { backgroundImage: `url( ${pageBackgroundImage} )` }}
          >
            <div className={layoutContainer}>
              {pageLogo
                && (
                <img
                  className="u-block u-margin-center addon-page__image"
                  src={pageLogo}
                  alt=""
                />
                )
                }
              {pageHeading
                && <p className="h2 alt u-text-shadow">{pageHeading}</p>}
              {pageBannerText
              && <p className="h3 u-standout-heading l-margin-top">{pageBannerText}</p>}
            </div>
          </div>
          <div className="u-center-canvas l-padding-top">
            <div className="l-col-12 u-clearfix">
              {ticketInformation
              && <p className="h5 u-no-margin">{ticketInformation}</p>}
            </div>
          </div>

        </div>
      </div>

    </LayoutContainer>
  );
};
