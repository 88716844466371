// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styling
import { titleBlock, main, sub } from './styles.module.css';

const TitleBlock = ({ mainHeading, subHeading, onThemeBackground }) => (
  <div
    className={`${titleBlock} ${!onThemeBackground && 'theme-color-primary'}`}
  >
    {mainHeading && (
      <h2
        className={`${main} h1 headingFont ${!onThemeBackground
          && 'theme-color-primary'}`}
      >
        {mainHeading}
      </h2>
    )}

    {subHeading && (
      <p className={`${sub} h3 ${!onThemeBackground && 'theme-color-primary'}`}>
        {subHeading}
      </p>
    )}
  </div>
);

TitleBlock.propTypes = {
  mainHeading: PropTypes.string,
  subHeading: PropTypes.string,
  onThemeBackground: PropTypes.bool,
};

TitleBlock.defaultProps = {
  mainHeading: '',
  subHeading: '',
  onThemeBackground: false,
};

export default TitleBlock;
